<div class="container-fluid" [class]="bgcolor">
  <!-- Main Content -->
  <div id="main" class="site-main">
    <!-- Page changer wrapper -->
    <div class="pt-wrapper">
      <!-- Subpages -->
      <div class="subpages">
        <!-- Home Subpage -->
        <section class="pt-page" data-id="home">
          <div class="section-inner start-page-content">
            <div class="page-header">
              <div class="row">
                <div class="col-sm-4 col-md-4 col-lg-4">
                  <div class="photo" *ngIf="profileData.logo">
                    <img [src]="profileData.logo" id="logo" alt="">
                  </div>
                </div>
                <div class="col-sm-8 col-md-8 col-lg-8">
                  <div class="title-block">
                    <h1 id="displayName">{{profileData.displayName}}</h1>
                    <div class="owl-carousel text-rotation">
                      <div class="item">
                        <div class="sp-subtitle">{{profileData.tagline}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="page-content">
              <div class="row">
                <div class="col-12">
                  <div class="about-me">
                    <div class="block-title">
                      <h3>On our <span>Way</span></h3>
                    </div>
                    <p id="message">The below map will update around once per minuite while we travel to you.</p>
                  </div>
                </div>
              </div>

             
              
              <div class="row equal-height-row justify-content-md-center" style="display: flex; flex-wrap: wrap;">
                <div id="map" style="width: 100%; height: 400px;"></div>
              </div>


              <div class="row">
                <div class="col-12">
                  <div class="about-me">
                    <div class="block-title">
                      <h3>Leave a <span>Review</span></h3>
                    </div>
                    <p id="message">{{profileData.message}}</p>
                  </div>
                </div>
              </div>
              <div class="row equal-height-row justify-content-md-center" style="display: flex; flex-wrap: wrap;">
                <div class="col-6 col-md-4 col-lg-3 col-xl-2 card" style="margin-bottom:0;padding:15px; display: flex; flex-direction: column;" *ngFor="let link of links">
                  <div class="download-resume" style="margin-top:0px;">
                    <h3 style="margin-top:0px; overflow: hidden; text-overflow: ellipsis; height:1.5em;">{{link.name}}</h3>
                    <div [ngSwitch]="link.name">
                      <p *ngSwitchCase="'Google'">Google Account Required</p>
                      <p *ngSwitchCase="'Facebook'">Facebook Account Required</p>
                      <p *ngSwitchCase="'NextDoor'">Next Door Account Required</p>
                      <p *ngSwitchDefault>No Account Required</p>
                    </div>
                    <br />
                    <a [href]="link.url" style="bottom: 10px; position: absolute;" class="btn btn-primary">Leave Review >></a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <!-- End of Home Subpage -->
      </div>
    </div>
    <!-- /Page changer wrapper -->
  </div>
  <!-- /Main Content -->
</div>
