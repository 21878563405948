<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-dark.png" alt="" height="45">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="45">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-light.png" alt="" height="45">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="45">
          </span>
        </a>
      </div>
    </div>
    <div class="d-flex">

      <a href="/account/signup" class="my-3 btn btn-primary btn-lg btn-rounded d-none d-sm-inline">
         Get Locksley Now
      </a>

      <button type="button" class="btn btn-sm px-3 font-size-24 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="ri-menu-2-line align-middle"></i>
      </button>

      <!-- App Search-->
    </div>

 
  </div>
</header>
