<div class="container-fluid">
  <app-pagetitle title="Pricing" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>


    <div class="row justify-content-md-center">
      <div class="col-xl-4 col-sm-6 col-12">
        <div class="card border border-primary pricing-box">
          <div class="card-body p-4">
                <h2 class="my-0">Locksley <span class="text-danger">One Plan</span></h2>
                <h4 class="fw-bold my-1">
                  <sup class="me-1">
                    <small>£</small>
                  </sup>
                
                  <span>9.99</span>
                  <small class="text-muted pl-2" style="padding-left:5px;">per month</small>
                </h4>
                
  
              <p>One simple plan - we like to make things easy!</p>
              <div class="font-size-14 mt-2 pt-2">
                <ul class="list-unstyled plan-features">
                  <li>Full Access to all our sections</li>
                  <li>No minimum term</li>
                  <li>Includes Mortice section</li>
                  <li>Includes Gearbox section</li>
                  <li>Includes Cylinder section</li>
                  <li>Regular updates</li>
                </ul>
              </div>
  
              <div class="my-2 mx-auto text-center">
 
  
                <a href="/account/signup" class="btn btn-danger w-md">Subscribe Now</a>
              </div>

  </div>
          </div>
        </div>
      </div>

 
 
</div>