<div class="container-fluid">
  <app-pagetitle title="Products" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  
 

    <div class="row">
      <div class="col-12">
        <h4 class="my-3">Our Tools</h4>
        <div class="card">
          <div class="row g-0 align-items-start">
            
            <div class="col-md-4">
              <img class="card-img img-fluid" src="assets/images/tool.jpeg" alt="Card image">
            </div>
            <div class="col-md-8">
              <h4 class="card-header mt-0">LOCKSLEY MORTICE KIT</h4>
              <div class="card-body">
                <h5 class="card-title">Works on ALL Mortice Locks</h5>
                <p class="card-text">Our mortice kit has been uniquely designed to speed up lock identification and assist in accurate measurements for when NDE options fail. It will help you identify gauge, keyway height as well as ensure that your drill point measurements are 100% accurate.</p>
                <a href="https://buy.stripe.com/9AQ3dqaHH5obc6s4gg" class="btn btn-danger">Buy Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
 
    </div>
    <!-- end row -->

 
</div>