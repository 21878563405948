<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        2024 © Locksley.app LLP.
      </div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block">
          <a href="/legal">Legal</a>
        </div>
      </div>
    </div>
  </div>
</footer>