import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

/**
 * Home Component
 */
export class HomeComponent implements OnInit {
  showNavigationArrows: any;
  showNavigationIndicators: any;
 
  constructor() {
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.showNavigationArrows=true;
    this.showNavigationIndicators=true;
    this.breadCrumbItems = [{ label: 'Locksley' }, { label: 'Home', active: true }];
  }
}
