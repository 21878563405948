<div class="container-fluid">
  <app-pagetitle title="App" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  

  <div class="px-4 py-5 my-5 text-center">
    <h1 class="display-5 fw-bold text-body-emphasis">From just £9.99/month!</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">No minimum contract, no cancellation period, no hidden fees—just straightforward service!</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <a href="/account/signup" class="btn btn-danger btn-lg px-4 gap-3">Get Locksley Now</a>
      </div>
    </div>
  </div>

  

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="row g-0 align-items-start">
          
          <div class="col-md-2">
            <img class="  img-fluid" src="assets/images/apple/01.png" alt="Card image cap">
          </div>
          <div class="col-md-2  d-none d-md-block">
            <img class="  img-fluid" src="assets/images/apple/02.png" alt="Card image cap">
          </div>
          <div class="col-md-8">
            <h4 class="card-header mt-0">LOCKSLEY APP</h4>
            <div class="card-body">
              <p class="card-text">
                Unleash the full potential of every locksmithing challenge with Locksley, your indispensable companion for mastering the art of locks. Tailored for both seasoned professionals and passionate enthusiasts, Locksley offers an expansive repository of locksmithing expertise, covering everything from mortice locks and gearboxes to cylinders, hinges, standards, and beyond. Stay ahead of the curve with our continuous updates, ensuring that there's always more to explore and conquer on the horizon.</p>

<h5 class="mt-3 foont-size-15">Why Choose Locksley?</h5>
<p class="card-text">Quick Reference Guides: Get instant access to a wealth of information on locks and security systems, including detailed diagrams and filters instructions for identifying and working with various lock mechanisms.




              </p>
              <a href="/account/signup" class="btn btn-danger">Register Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="row g-0 align-items-start">
          <div class="col-md-10">
            <h4 class="card-header mt-0">Mortice Locks</h4>
            <div class="card-body">
 
              <p class="card-text">Mortice Lock Identification: Say goodbye to uncertainty with Locksley's advanced mortice lock identification feature. Effortlessly discern and comprehend a plethora of mortice locks with our detailed breakdowns, empowering you to streamline your operations and elevate your proficiency. Explore the most extensive array of mortice locks available in any manual, boasting a wealth of exclusive locks not found elsewhere. Unlock a world of possibilities and take your locksmithing skills to unprecedented heights with Locksley.</p>
              <a href="/account/signup" class="btn btn-danger">Register Now</a>
            </div>
          </div>
          <div class="col-md-2">
            <img class="  img-fluid" src="assets/images/apple/03.png" alt="Card image cap">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="row g-0 align-items-start">
          <div class="col-md-3">
            <img class="  img-fluid" src="assets/images/apple/04.png" alt="Card image cap">
          </div>
          <div class="col-md-9">
            <h4 class="card-header mt-0">Gearboxes</h4>
            <div class="card-body">
              
              <p class="card-text">Gearbox Identification: Delve into the intricate realm of lock gearboxes with Locksley's comprehensive identification guides. Navigate through complex gearbox mechanisms with ease, thanks to our user-friendly interface designed to streamline diagnosis and maintenance tasks, ultimately saving you valuable time and effort. Explore the most extensive collection of gearbox details available to locksmiths, featuring comprehensive insights, detailed photos both internally and externally, along with invaluable opening techniques, drill points, and beyond. Elevate your locksmithing prowess and conquer any gearbox challenge with confidence, courtesy of Locksley.</p>
              <a href="/account/signup" class="btn btn-danger">Register Now</a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="row g-0 align-items-start">
          <div class="col-md-9">
            <h4 class="card-header mt-0">Cylinders</h4>
            <div class="card-body">
 
              <p class="card-text">High-Security Cylinder Insights: Propel your understanding of high-security cylinders to new heights with Locksley. Stay abreast of cutting-edge cylinder technology, security enhancements, and advanced picking resistance strategies to maintain your competitive edge in the locksmithing realm. Our comprehensive breakdowns offer in-depth analysis of each cylinder's security elements, empowering you to truly comprehend and anticipate every challenge. Explore the arsenal of dedicated tools specifically engineered to facilitate cylinder opening, ensuring you're equipped to triumph whenever the need arises. With Locksley as your guide, you'll master the art of locksmithing with precision and confidence, staying one step ahead of the game at all times.
              </p>
              <a href="/account/signup" class="btn btn-danger">Register Now</a>
            </div>
          </div>
          <div class="col-md-3">
            <img class="  img-fluid" src="assets/images/apple/05.png" alt="Card image cap">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="row g-0 align-items-start">
          <div class="col-md-3">
            <img class="  img-fluid" src="assets/images/apple/06.png" alt="Card image cap">
          </div>
          <div class="col-md-9">
            <h4 class="card-header mt-0">Updates and Acessibility</h4>
            <div class="card-body">
 
              <p class="card-text">
                On-The-Go Accessibility: Whether you find yourself on a job site or honing your craft from the comfort of home, Locksley provides unparalleled convenience by placing your entire locksmithing reference library right in the palm of your hand.
                </p><p class="card-text">
                Regular Updates: At Locksley, we pride ourselves on our commitment to regular updates. Since our launch, we've consistently delivered major updates approximately every quarter. Our dedication ensures that you always have access to the most up-to-date information and an expanding array of features right at your fingertips.
              </p>
              <a href="/account/signup" class="btn btn-danger">Register Now</a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="row g-0 align-items-start">
          <div class="col-md-9">
            <h4 class="card-header mt-0">And More</h4>
            <div class="card-body">
            
              <p class="card-text">Hinge Adjustment Tutorials: Perfect the art of hinge adjustment with our easy-to-follow guides. From door realignment to ensuring smooth operation, Locksley covers all aspects of hinge maintenance.
              </p>

 




              <p class="card-text">Step up your locksmithing game. Whether you’re solving the day’s challenges or mastering new skills, Locksley is your go-to resource. Download now and unlock the potential of every lock!
                </p>
              <a href="/account/signup" class="btn btn-danger">Register Now</a>
            </div>
          </div>
          <div class="col-md-3">
            <img class="  img-fluid" src="assets/images/apple/07.png" alt="Card image cap">
          </div>
        </div>
      </div>
    </div>
  </div>

 
  
  
</div>