import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LXYReviews } from './lxyreviews/lxyreviews.component';
import { LXYTrack } from './lxytrack/lxytrack.component';
import { LayoutComponent } from './layouts/layout/layout.component';

const routes: Routes = [
  { path: 'account',  component: LayoutComponent, loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: '',         component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  { path: 'reviews/:id', component: LXYReviews },
  { path: 'lxytrack/:id', component: LXYTrack }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
