import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../core/services/auth.service';


@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})

/**
 * ALS Component
 */
export class StripeComponent implements OnInit {

 
  constructor(private authService: AuthenticationService) {
    
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Locksley' }, { label: 'Stripe', active: true }];
    localStorage.setItem('cpn', '');
    this.authService.logout();
  }
}
