<div class="container-fluid">
 
  <div class="row">
    <div class="col-12">
      <!-- Simple card -->
      <div class="card py-5 m-0 mainCard">
   
        <div class="card-body">
          <h1 class="card-title mt-5">AN APP FOR <span>LOCKSMITHS</span> BY <span>LOCKSMITHS</span></h1>
          <p class="card-text mb-5">The only app you’ll ever need for identifying and opening locks and security products in the UK.</p>
        </div>
      </div>
    </div>
  </div>

  
  <ngb-carousel [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators" class="my-3 homeslider">
	 
			<ng-template ngbSlide>
        <div class="cards-wrapper">

          <div class="card col-6 col-md-4 hreview">
            <div class="card-header item">
              A great tool!
              <div class="text-muted me-3">
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
              </div>
            </div>  
            <div class="card-body">
              <blockquote class="card-blockquote mb-0 description">
                <p>Had it a while and has proved invaluable time and tome again whether it’s a lock or mech I’ve not seen in a while or I’m just having a senior moment has helped on a lonely moment quite a few times.</p>
                <footer class="blockquote-footer font-size-12 reviewer">
                  Porkypig628 <cite title="Source Title" class="dtreviewed">App Store - Apr 7, 2024</cite>
                </footer>
              </blockquote>
            </div>
          </div>        
        
             
          <div class="card col-6 col-md-4">
            <div class="card-header">
              Outstanding!
              <div class="text-muted me-3">
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
              </div>
            </div>  
            <div class="card-body">
              <blockquote class="card-blockquote mb-0">
                <p>I’ve been using the Locksley website since day one and I love it! I’ve seen the information and the detail grow over time, it’s now my most used app on my phone and I can’t remember life locksmithing before Locksley. You’ve built an incredibly popular and extremely useful product to a niche trade, on behalf of all lockies, thank you. </p>
                <footer class="blockquote-footer font-size-12">
                  StevieM86 <cite title="Source Title">App Store - Jan 17, 2024</cite>
                </footer>
              </blockquote>
            </div>
          </div>        
        
           
   

        </div>
			</ng-template>
      <ng-template ngbSlide>
        <div class="cards-wrapper">

          <div class="card col-6 col-md-4">
            <div class="card-header">
              Play Store
              <div class="text-muted me-3">
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
              </div>
            </div>  
            <div class="card-body">
              <blockquote class="card-blockquote mb-0">
                <p>I love this app. I use it daily as a locksmith to identify the locks, gearboxes and hinges i come across daily.</p>
                <footer class="blockquote-footer font-size-12">
                  John LeSurf <cite title="Source Title">Play Store - Dec 28, 2023</cite>
                </footer>
              </blockquote>
            </div>
          </div>  
          
          <div class="card col-6 col-md-4">
            <div class="card-header">
              Play Store
              <div class="text-muted me-3">
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
              </div>
            </div>  
            <div class="card-body">
              <blockquote class="card-blockquote mb-0">
                <p>Having been privileged to be using this app since the first minute of its existence and testing it constantly I can honestly say this app is the ultimate locksmith sidekick. So this app is ALWAYS being updated with new and old hardware and as much information as possible to aid the locksmith in opening locks.
                </p>
                <footer class="blockquote-footer font-size-12">
                  Dan the Lockman <cite title="Source Title">Play Store - Apr 23, 2023</cite>
                </footer>
              </blockquote>
            </div>
          </div>   
        
        
  

        </div>
			</ng-template>
      <ng-template ngbSlide>
        <div class="cards-wrapper">

           
        
        
          <div class="col-6 col-md-4 card">
            <div class="card-header">
              The Locksmith Journal
              <div class="text-muted me-3">&nbsp;</div>
            </div>  
            <div class="card-body">
              <blockquote class="card-blockquote mb-0">
                <p>I have recently been shown the app from Locksley and was impressed with the quality and ease of use. It is a small monthly subscription and I am not affiliated with it, but I would recommend it for any new or established locksmith...</p>
                <footer class="blockquote-footer font-size-12">
                  GJ Locks <cite title="Source Title">The Locksmith Journal #91</cite>
                </footer>
              </blockquote>
            </div>
          </div>    

        </div>
			</ng-template>
	</ngb-carousel>


  

 
 

  <div class="row">
    <div class="col-12">
      <h2 class="my-3">Our Locksmith App</h2>
      <div class="card-deck-wrapper">
        <div class="card-group">
          <div class="card mb-4">
            <img class="card-img-top img-fluid" src="assets/images/apple/01.webp" alt="Card image cap">
          </div>
          <div class="card mb-4 d-none d-md-block">
            <img class="card-img-top img-fluid" src="assets/images/apple/02.webp" alt="Card image cap">
          </div>
          <div class="card mb-4 d-none d-md-block">
            <img class="card-img-top img-fluid" src="assets/images/apple/03.webp" alt="Card image cap">
          </div>       
          <div class="card mb-4 d-none d-md-block">
            <img class="card-img-top img-fluid" src="assets/images/apple/04.webp" alt="Card image cap">
          </div>    
          <div class="card mb-4 d-none d-md-block">
            <img class="card-img-top img-fluid" src="assets/images/apple/05.webp" alt="Card image cap">
          </div>    
          <div class="card mb-4 d-none d-md-block">
            <img class="card-img-top img-fluid" src="assets/images/apple/06.webp" alt="Card image cap">
          </div>       
          <div class="card mb-4 d-none d-md-block">
            <img class="card-img-top img-fluid" src="assets/images/apple/07.webp" alt="Card image cap">
          </div>            
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
  <div class="row justify-content-md-center">
    <div class="col-12">
      <div class="card card-body">
        <h3 class="card-title mt-0">Find out more</h3>
        <p class="card-text">Maximize your locksmithing potential with Locksley, the premier mobile app for mastering locks. Tailored for locksmith professionals and enthusiasts alike, Locksley offers an extensive repository of expertise, covering everything from mortice locks and gearboxes to cylinders, hinges, and industry standards. Stay ahead of the competition with our continuous updates, ensuring there's always more to explore and conquer in the dynamic world of locksmithing. Download the Locksley app now and unlock the secrets to locksmithing success right at your fingertips.</p>
        <a href="/app" class="btn btn-danger">Get the Locksley App</a>
      </div>
    </div>
  </div>
  <!-- end row -->
 

  

 


    <div class="row">
      <div class="col-12">
        <h2 class="my-3">Our Locksmith Tools</h2>
        <div class="card">
          <div class="row g-0 align-items-start">
            
            <div class="col-md-4">
              <img class="card-img img-fluid" src="assets/images/tool.webp" alt="Card image">
            </div>
            <div class="col-md-8">
              <h3 class="card-header mt-0">LOCKSLEY MORTICE KIT</h3>
              <div class="card-body">
                <h5 class="card-title">Works on ALL Mortice Locks</h5>
                <p class="card-text">Our mortice kit has been uniquely designed to speed up lock identification and assist in accurate measurements for when NDE options fail. It will help you identify gauge, keyway height as well as ensure that your drill point measurements are 100% accurate.</p>
                <a href="/products" class="btn btn-danger">Get it now!</a>
              </div>
            </div>
          </div>
        </div>
      </div>
 
    </div>
    <!-- end row -->


    <div class="row">
      <div class="col-12">
        <h2 class="my-3">Contact Us</h2>
        <div class="card">
 
              <h3 class="card-header mt-0">Tell us all about it!</h3>
              <div class="card-body">
                <p class="card-text">We love to hear from other locksmiths, whether you want to give us feedback, have ideas we could add, or just want to say "Hi". We always look forward to hearing from you. We are active locksmiths, so sometimes replies can be a little delayed (you know what jobs get like), but we genuinely want to hear from you.</p>
                <a href="/contact" class="btn btn-danger">Contact Us</a>
              </div>
       
        </div>
      </div>
 
    </div>
    <!-- end row -->
</div>

<script>
  $('#recipeCarousel').carousel({
  interval: 10000
})

$('.carousel .carousel-item').each(function(){
    var minPerSlide = 3;
    var next = $(this).next();
    if (!next.length) {
    next = $(this).siblings(':first');
    }
    next.children(':first-child').clone().appendTo($(this));
    
    for (var i=0;i<minPerSlide;i++) {
        next=next.next();
        if (!next.length) {
        	next = $(this).siblings(':first');
      	}
        
        next.children(':first-child').clone().appendTo($(this));
      }
});

</script>