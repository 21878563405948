<div class="container-fluid">
  <app-pagetitle title="Partner" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  
 

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="row g-0 align-items-start">
            <h4 class="card-header mt-0">{{partner}} & Locksley</h4>

            <div class="col-md-8">
            
              <div class="card-body">
                <h5 class="card-title">Unlock Your Potential with Our Exclusive Offer!</h5>
                <h6>Welcome {{partner}} {{partnerCust}}!</h6>
                <p class="card-text">
                  Are you ready to take your locksmith skills to the next level? As a special offer for {{partner}} {{partnerCust}}, we're excited to provide you with an exclusive opportunity.<!--to enhance your training and kickstart your career.--> 
                </p>

                
                <h6>What’s in it for you?</h6>
                
                <p class="card-text">1 Month Free: Enjoy full access to our premium features for an entire month, absolutely free!</p>
                <p class="card-text">20% Discount: Continue using our app with a 20% discount on all subscription plans.</p>
                <p class="card-text">Our app is designed to support locksmiths at every stage of their career. Whether you’re just starting out or looking to refine your expertise, our features will help you succeed. With intuitive tools, real-time updates, and a community of professionals, you'll have everything you need to excel in your craft.</p>
                
                <h6>Ready to Get Started?</h6>
                <p class="card-text">Sign Up: Click the button below to create your account.</p>
 
                <p class="card-text">Subscribe: Your free month and discount will be automatically applied when you hit Subscribe Now</p>

                <p class="card-text">Don’t miss out on this chance to gain a competitive edge.<!-- in your training.--> Sign up today and see why locksmiths everywhere are choosing our app to elevate their skills and careers.</p>
                
                <p class="card-text"><a href="/account/signup" class="btn btn-danger">Get the Locksley App</a></p>
                
                <p class="card-text">Empower yourself with the tools you need to succeed. Join us and become a master of your trade!</p>




              </div>
            </div>
            <div class="col-md-4">
              <img class="card-img img-fluid" src="assets/images/teamup.svg" alt="Card image">
            </div>

          </div>
        </div>
      </div>
 
    </div>
  <!--   end row -->

 
</div>