// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'firebase',
  firebaseConfig: {
    apiKey: "AIzaSyBMiVEJ17Jxk_MsNf_WlZ6Rmjt0W0wUt5k",
    authDomain: "locksley-app.firebaseapp.com",
    projectId: "locksley-app",
    storageBucket: "locksley-app.appspot.com",
    messagingSenderId: "469980838226",
    appId: "1:469980838226:web:16af5186c48316555aa28b",
    measurementId: "G-E95RL0E2D9",
    databaseURL: "https://locksley-app-default-rtdb.europe-west1.firebasedatabase.app"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
