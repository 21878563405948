import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})

/**
 * About Component
 */
export class PricingComponent implements OnInit {

 
  constructor() {
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Locksley' }, { label: 'Pricings', active: true }];
  }
}
