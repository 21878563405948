import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})

/**
 * ALS Component
 */
export class PartnersComponent implements OnInit {
  partner='ALS';
  partnerCust='customers'
  public id: string | null = null;

  constructor(private route: ActivatedRoute) { }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Locksley' }, { label: 'Partners', active: true }];

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      // Add your logic here to handle different 'id' values
      console.log(this.id); // For example, you can log the id or use it to display different content
      switch(this.id){
        case 'ALS':
          this.partner = 'ALS';
          this.partnerCust='customers';
          localStorage.setItem('cpn', 'promo_1P7wc0AKWltsKOeZNMmbcecD'); // ALS 1 Month free
          localStorage.setItem('cpn2', 'price_1PDnfJAKWltsKOeZRfs0tYMp'); //7.99
        break;
        case 'LL1':
          this.partner = 'Locklab 101';
          this.partnerCust='students';
          localStorage.setItem('cpn', 'promo_1PLOZfAKWltsKOeZExENWezA'); // ALS 1 Month free
          localStorage.setItem('cpn2', 'price_1PDnfJAKWltsKOeZRfs0tYMp'); //7.99
        break;        
        case 'MPL':
          this.partner = 'MPL';
          this.partnerCust='students';
          localStorage.setItem('cpn', 'promo_1PLR34AKWltsKOeZFhNliI5n'); // ALS 1 Month free
          localStorage.setItem('cpn2', 'price_1PDnfJAKWltsKOeZRfs0tYMp'); //7.99
        break;          
        case 'LTM':
          this.partner = 'Locksmith Training Merseyside';
          this.partnerCust='students';
          localStorage.setItem('cpn', 'promo_1PDnRSAKWltsKOeZRpmHycPe');// 1 Month free
          localStorage.setItem('cpn2', 'price_1PDnfJAKWltsKOeZRfs0tYMp');//7.99
        break;

        case 'LTS':
          this.partner = 'Locksmith Training Scotland';
          this.partnerCust='students';
          localStorage.setItem('cpn', 'promo_1PIS9tAKWltsKOeZuzfJkXGH');// 1 Month free
          localStorage.setItem('cpn2', 'price_1PDnfJAKWltsKOeZRfs0tYMp');//7.99
        break;

        case 'LLT':
          this.partner = 'London Locksmith Training';
          this.partnerCust='students';
          localStorage.setItem('cpn', 'promo_1PISANAKWltsKOeZN7s3CQdn');// 1 Month free
          localStorage.setItem('cpn2', 'price_1PDnfJAKWltsKOeZRfs0tYMp');//7.99
        break;
        
        case 'JJH':
          this.partner = 'Jason Hillier';
          this.partnerCust='students';
          localStorage.setItem('cpn', 'promo_1PITAiAKWltsKOeZHbd8DMn3');// 1 Month Free
          localStorage.setItem('cpn2', 'price_1PDnfJAKWltsKOeZRfs0tYMp');//7.99
        break;        

        case 'SLT':
          this.partner = 'South UK Locksmith Training';
          this.partnerCust='students';
          localStorage.setItem('cpn', 'promo_1PIZGgAKWltsKOeZVqKlRmpN');// 1 Month Free
          localStorage.setItem('cpn2', 'price_1PDnfJAKWltsKOeZRfs0tYMp');//7.99
        break;
        
        case 'FLT':
          this.partner = 'Fortress Locksmith Training';
          this.partnerCust='students';
          localStorage.setItem('cpn', 'promo_1PIZIJAKWltsKOeZjiq47EP2');// 1 Month Free
          localStorage.setItem('cpn2', 'price_1PDnfJAKWltsKOeZRfs0tYMp');//7.99
        break;


        default:

        break;
      }



    });






   // window.location.href = '/';
  }
}
 


