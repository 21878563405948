<div class="container-fluid">
  <app-pagetitle title="About Us" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>


  <div class="row">
    <div class="col-12">
      <div class="card">
        <h4 class="card-header mt-0">Locksmiths by trade</h4>
        <div class="card-body">
         
          <p class="card-text">Locksley is a team of locksmiths with over 35 years of combined experience in the locksmith industry. We have designed an app that will not only help you identify and open locks but will also help you run your business!</p>
          <p class="card-text">Founded in 2022 by Matt and Mike, Locksley was an immediate hit with experienced locksmiths and those that were new to the trade and eager to learn opening techniques and lock identification, as the subscribers increased so did our database of locks and we now boast a catalogue of over 800 different locking products which is growing every week!</p>
          <p class="card-text">A late addition to our team was Mark in 2024, he has helped us with business development, and we’ve also benefitted from his wealth of knowledge from working in the industry for over 25 years.</p>
          <p class="card-text">Every feature we introduce undergoes rigorous real-world testing. We rely on our own app and tools in our day-to-day work, ensuring not only their effectiveness but also their practicality. Whether it's a new addition born out of our own needs or a feature you've requested, we prioritize functionality and usefulness.</p>
          <p class="card-text">Our commitment to constant improvement is fuelled by the invaluable feedback we receive from professionals like yourself. We're always evolving, refining, and expanding our offerings to better meet your needs.</p>
          <p class="card-text">Finally, we hope you enjoy using the app, we’ve certainly enjoyed making it! There’s never going to be a point when we stop work on the App or stop adding new products or features. There’s plenty more on the way, watch this space!!!</p>
         
         
         <!--
          <p class="card-text">At Locksley, our team are locksmiths through and through, and it's this hands-on experience that truly sets us apart. Unlike some companies that dictate how you should operate or force you to conform to a specific way of working, we're out in the field, facing the same challenges as you.</p>

          <p class="card-text">Every feature we introduce undergoes rigorous real-world testing. We rely on our own app and tools in our day-to-day work, ensuring not only their effectiveness but also their practicality. Whether it's a new addition born out of our own needs or a feature you've requested, we prioritize functionality and usefulness.</p>
          
          <p class="card-text">Our commitment to constant improvement is fuelled by the invaluable feedback we receive from professionals like yourself. We're always evolving, refining, and expanding our offerings to better meet your needs.</p>
          
          <p class="card-text">Looking back, we've seen numerous locksmith apps come and go. However, our determination lies in not becoming just another footnote in the history of locksmithing tools. We've already expanded our collection to unprecedented levels within an app, and we continue to branch out into new areas such as gearboxes and cylinders. Why? Because we understand that Locksley's true value lies in providing you with easy access to the information you need to excel in your work.</p>


-->




        </div>
      </div>
    </div>

    <div class="row">
     
        <h4 class="my-3">The Team</h4>
 
            <div class="col-12 col-md-4 card mb-4">
              <img class="card-img-top img-fluid rounded-circle" src="https://eu.ui-avatars.com/api/?name=Matt+Little&size=75" alt="Card image cap">

              

              <div class="card-body">
                <h4 class="card-title mt-0">Matt Little</h4>
                <p class="card-text"> <small class="text-muted">Code Geek</small> </p>
                <p class="card-text">
                  Before diving into locksmithing, Matt spent a solid 25 years in web and app development, rubbing shoulders with big-name brands worldwide. Now, he's the tech whiz behind Locksley, building the stuff that makes it tick on the web and your phone.</p>
                
              </div>
            </div>
            <div class="col-12 col-md-4 card mb-4">
              <img class="card-img-top img-fluid rounded-circle" src="https://eu.ui-avatars.com/api/?name=Mike+Green&size=75" alt="Card image cap">
              <div class="card-body">
                <h4 class="card-title mt-0">Mike Green</h4>
                <p class="card-text"> <small class="text-muted">Obsessive Collector</small> </p>
                <p class="card-text">
                  Meet Mike, our team's resident collector extraordinaire. He's fueled by the thrill of discovering new treasures for our collection. With a passion for delving deep into locks, his obsession has been the driving force behind Locksley's expansion in both size and intricate detail.
</p>
                
              </div>
            </div>
            <div class="col-12 col-md-4 card mb-4">
              <img class="card-img-top img-fluid rounded-circle" src="https://eu.ui-avatars.com/api/?name=Mark+Grainger&size=75" alt="Card image cap">
              <div class="card-body">
                <h4 class="card-title mt-0">Mark Grainger</h4>
                <p class="card-text"><small class="text-muted">QML</small></p>
                <p class="card-text">With over 25 years entrenched in the locksmith industry, Mark brings a wealth of invaluable knowledge and business acumen to the Locksley team. His track record of multiple successful ventures is a testament to his prowess, making him an invaluable asset to our team.</p>

              </div>
 
       
      </div>
    </div>
    <!-- end row -->

  <div class="row justify-content-center">
    <div class="col-xl-10">
      <h4 class="my-3">Our History</h4>
      <div class="timeline" dir="ltr">

        <div class="timeline-item timeline-left">
          <div class="timeline-block">
            <div class="time-show-btn mt-0">
              <a href="javascript: void(0);" class="btn btn-danger btn-rounded w-lg">2022</a>
            </div>
          </div>
        </div>

        <div class="timeline-item timeline-left">
          <div class="timeline-block">
            <div class="timeline-box card">
              <div class="card-body">
                <span class="timeline-icon"></span>
                <div class="timeline-date">
                  <i class="mdi mdi-circle-medium circle-dot"></i>
                  Summer
                </div>
                <h5 class="mt-3 foont-size-15">Mortice Manual</h5>
                <div class="text-muted">
                  <p class="mb-0">In late 2022, Matt and Mike embarked on a project to compile a comprehensive mortice manual for use among colleagues. They observed that many existing manuals in the industry were outdated, contained inaccurate measurements, and lacked information on the locks they encountered in their work.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
   
        <div class="timeline-item">
          <div class="timeline-block">
            <div class="timeline-box card">
              <div class="card-body">
                <span class="timeline-icon"></span>
                <div class="timeline-date">
                  <i class="mdi mdi-circle-medium circle-dot"></i>
                  December
                </div>
                <h5 class="mt-3 foont-size-15">Side Kick</h5>
                <div class="text-muted">
                  <p class="mb-0">With over 20 years of experience in the tech industry prior to transitioning to locksmithing, Matt brought a unique perspective to the table. He recognised the need for a more accessible and mobile-friendly solution for locksmiths like himself. Thus, the initial version of what would eventually evolve into Locksley was born. Originally known as Sidekick, it featured a streamlined mortice manual equipped with user-friendly filters, designed to facilitate quick and efficient lock searches on mobile devices.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="timeline-item timeline-left">
          <div class="timeline-block">
            <div class="time-show-btn mt-0">
              <a href="javascript: void(0);" class="btn btn-danger btn-rounded w-lg">2023</a>
            </div>
          </div>
        </div>

        <div class="timeline-item timeline-left">
          <div class="timeline-block">
            <div class="timeline-box card">
              <div class="card-body">
                <span class="timeline-icon"></span>
                <div class="timeline-date">
                  <i class="mdi mdi-circle-medium circle-dot"></i>
                  January
                </div>
                <h5 class="mt-3 foont-size-15">Locksley was born</h5>
                <div class="text-muted">
                  <p class="mb-0">Impressed by the success of Sidekick, Matt and Mike recognised a broader opportunity waiting to be seized. Mike's deep passion for locks extended beyond mortices; he possessed a wealth of knowledge encompassing all types of locks, from cylinders to gearboxes. His expertise in lock identification, opening techniques, and tools proved to be invaluable. Motivated by their collective vision, they embarked on the ambitious endeavour of building Locksley. This comprehensive platform would not only cover mortices but also include cylinders and gearboxes, offering locksmiths a wealth of essential information in one accessible tool.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="timeline-item">
          <div class="timeline-block">
            <div class="timeline-box card">
              <div class="card-body">
                <span class="timeline-icon"></span>
                <div class="timeline-date">
                  <i class="mdi mdi-circle-medium circle-dot"></i>
                  Febuary
                </div>
                <h5 class="mt-3 foont-size-15">Release 1</h5>
                <div class="text-muted">
                  <p class="mb-0">Release 1 of Locksley made a significant impact, drawing in a surge of subscribers. It became evident that we had struck the perfect chord in the market, meeting a critical need among locksmiths.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="timeline-item timeline-left">
          <div class="timeline-block">
            <div class="timeline-box card">
              <div class="card-body">
                <span class="timeline-icon"></span>
                <div class="timeline-date">
                  <i class="mdi mdi-circle-medium circle-dot"></i>
                  March
                </div>
                <h5 class="mt-3 foont-size-15">LockExpo '23</h5>
                <div class="text-muted">
                  <p class="mb-0">Following invaluable feedback from industry professionals, we swiftly rolled out Release 2 of Locksley, just in time for Lockexpo '23. Packed with even more content and features, our enhanced app garnered widespread attention, allowing us to showcase its capabilities to an even larger audience.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="timeline-item">
          <div class="timeline-block">
            <div class="timeline-box card">
              <div class="card-body">
                <span class="timeline-icon"></span>
                <div class="timeline-date">
                  <i class="mdi mdi-circle-medium circle-dot"></i>
                  Summer
                </div>
                <h5 class="mt-3 foont-size-15">Release 3</h5>
                <div class="text-muted">
                  <p class="mb-0">Taking into account the invaluable feedback received from users and the insights gained from LockExpo, we diligently worked on Release 3 of Locksley. This iteration aimed to incorporate even more content and features, further enhancing the utility and value of our app.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="timeline-item timeline-left">
          <div class="timeline-block">
            <div class="timeline-box card">
              <div class="card-body">
                <span class="timeline-icon"></span>
                <div class="timeline-date">
                  <i class="mdi mdi-circle-medium circle-dot"></i>
                  October
                </div>
                <h5 class="mt-3 foont-size-15">MLA Expo</h5>
                <div class="text-muted">
                  <p class="mb-0">At our second showing, this time at a significant event, we seized the opportunity not only to showcase the Locksley app but also to unveil our highly anticipated mortice drill kit. Additionally, we leveraged the occasion to introduce Release 4 of Locksley. By this stage, we had nearly doubled our collection size, solidifying our position with what we believed to be the most extensive mortice manual generally available to locksmiths.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="timeline-item timeline-left">
          <div class="timeline-block">
            <div class="time-show-btn mt-0">
              <a href="javascript: void(0);" class="btn btn-danger btn-rounded w-lg">2024</a>
            </div>
          </div>
        </div>
        <div class="timeline-item">
          <div class="timeline-block">
            <div class="timeline-box card">
              <div class="card-body">
                <span class="timeline-icon"></span>
                <div class="timeline-date">
                  <i class="mdi mdi-circle-medium circle-dot"></i>
                  January
                </div>
                <h5 class="mt-3 foont-size-15">Going Native</h5>
                <div class="text-muted">
                  <p class="mb-0">Although it was a process that took some time, we managed to squeeze out another release while simultaneously rebuilding the app from scratch. We made sure to retain the features and functionality that our users adored, but we also made a significant shift by transitioning to a 100% mobile-native platform. This strategic move enabled us to secure listings on both the Apple App Store and Google Play Store, expanding our reach and accessibility to a wider audience.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="timeline-item timeline-left">
          <div class="timeline-block">
            <div class="timeline-box card">
              <div class="card-body">
                <span class="timeline-icon"></span>
                <div class="timeline-date">
                  <i class="mdi mdi-circle-medium circle-dot"></i>
                  March
                </div>
                <h5 class="mt-3 foont-size-15">LockExpo '24</h5>
                <div class="text-muted">
                  <p class="mb-0">With just under a year of progress under our belts, we returned to Lock Expo with a surprise in store. To our delight, many individuals who were initially skeptical of the app came over to express their newfound admiration. Our continuous growth and meticulous attention to detail showcased the dedication and effort that went into making Locksley what it is today.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="timeline-item">
          <div class="timeline-block">
            <div class="timeline-box card">
              <div class="card-body">
                <span class="timeline-icon"></span>
                <div class="timeline-date">
                  <i class="mdi mdi-circle-medium circle-dot"></i>
                  April
                </div>
                <h5 class="mt-3 foont-size-15">Expanding</h5>
                <div class="text-muted">
                  <p class="mb-0">Our journey began at LockExpo, and after numerous discussions, we welcomed Mark to the team. While there's nothing tangible to show just yet, we're excited about the fantastic features we have in the pipeline. Some are already simmering away, while others are fresh ideas straight from Mark's expertise.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="timeline-item timeline-left">
          <div class="timeline-block">
            <div class="timeline-box card">
              <div class="card-body">
                <span class="timeline-icon"></span>
                <div class="timeline-date">
                  <i class="mdi mdi-circle-medium circle-dot"></i>
                  Future
                </div>
                <h5 class="mt-3 foont-size-15">More to come</h5>
                <div class="text-muted">
                  <p class="mb-0">Who knows what the future holds? While uncertainty may prevail, one thing is certain: our product backlog is continually expanding as we work on new features. With over two years' worth of updates already planned, many of which are nearing completion, there's plenty for us to be excited about and eager to share with you.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>