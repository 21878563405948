<div class="container-fluid">
  <app-pagetitle title="Products" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  
 

    <div class="row">
      <div class="col-12">
        <h4 class="my-3">Stripe Payment Return</h4>
        <div class="card">
          <div class="row g-0 align-items-start">
            <div class="col-md-12">
              <h4 class="card-header mt-0">Thanks!</h4>
              <div class="card-body">
                 
                <p class="card-text">If your payment was successful, you'll now be able to access the Locksley App, click below to log back in which will allow your subscription status to refresh. If you have already installed the app, please logout and back in again to refresh your subscription status.</p>
                <a href="/account/login" class="btn btn-danger">Login Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
 
    </div>
    <!-- end row -->

 
</div>