import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {
  // bread crumb items
  breadCrumbItems: Array<{}>;

 

  constructor() { }
  ngAfterViewInit(){
    document.getElementsByClassName('container-fluid')[0].scrollIntoView();
 }
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Locksley' }, { label: 'Account', active: true }];

     
  }
}
