import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import firebase from 'firebase/compat/app';
// Add the Firebase products that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

import { GooglePlacesService } from './google-places.service';
import { FacebookReviewsService } from './facebook-reviews.service';
import { CATReviewsService } from './cat-reviews.service';

@Component({
  selector: 'app-lxyreviews',
  templateUrl: './lxyreviews.component.html',
  styleUrls: ['./lxyreviews.component.scss']
})

/**
 * ALS Component
 */
export class LXYReviews implements OnInit {

  profileData: any;
  reviews: any[];
  GoogleReviews: any[];
  FacebookReviews: any[];
  CATReviews: any[];
  
  links: any[];
  bgcolor: any;


  constructor(
    private route: ActivatedRoute, 
    private firestore: AngularFirestore, 
    private googlePlacesService: GooglePlacesService,
    private facebookReviewsService: FacebookReviewsService,
    private CATReviewsService: CATReviewsService) {
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Locksley' }, { label: 'Reviews', active: true }];
    const profileId = this.route.snapshot.paramMap.get('id');
 

    if(this.route.snapshot.paramMap.get('seoref')){
      this.bgcolor = this.route.snapshot.paramMap.get('seoref');
    }

  // Fetch profile data from Firestore
  this.firestore.collection('profiles').doc(profileId).valueChanges().subscribe((profile: any) => {
   

    //profile.Links.CheckATrade
    //https://www.checkatrade.com/give-feedback/trades/lockfit994137
    //https://www.checkatrade.com/trades/lockfit994137



    
    if(profile.Links.Google){
      // Once profile data is fetched, get the placeId and fetch Google reviews
 
      var myArray = profile.Links.Google.split("!");
     
      for(var i=0; i<myArray.length; i++) {
        if(myArray[i].length == 39){
          var placeId = myArray[i].substring(2);
        }
      }

      profile.Links.Google = `https://www.google.com/maps/place//data=!4m3!3m2!1s${placeId}!12e1?source=g.page.m._&laa=merchant-review-solicitation`;



      
      this.googlePlacesService.getReviews(placeId).subscribe((data: any) => {
        
        this.GoogleReviews = data.reviews;
        this.mergeReviews();
      });
    }


    if(profile.Links.CheckATrade) {
      var myArray = profile.Links.CheckATrade.split("/");
      var catId = myArray[myArray.length-1];


      
      profile.Links.CheckATrade=`https://www.checkatrade.com/give-feedback/trades/${catId}`;
     
     
      this.CATReviewsService.getReviews(catId)
      .subscribe((data: any) => {
        this.CATReviews = data;

       this.mergeReviews();
      });


    }



    if(profile.Links.Facebook){
      const fbId ='LockfitSlough';
     
      this.facebookReviewsService.getReviews(fbId)
      .subscribe((data: any) => {
        this.FacebookReviews = data.data;
       
        this.mergeReviews();
      });
    }

    this.profileData = profile;
    this.links = Object.keys(profile.Links).map(key => ({ name: key, url: profile.Links[key] }));
    if(!this.route.snapshot.paramMap.get('seoref')){
    this.bgcolor=profile.bgcolor;
    }


  });

  }

  mergeReviews(){
    var mergedReviews=[];
    if(this.GoogleReviews && this.GoogleReviews.length > 0){
      var mergedReviews = [...mergedReviews,...this.GoogleReviews];
    }


    if(this.FacebookReviews && this.FacebookReviews.length > 0){
      const formattedFacebookReviews = this.FacebookReviews.map(review => {
        return {
            review_id: review.id, // Assuming Facebook review id is unique
            reviewer: {
                profile_photo_url: review.reviewer.profile_photo_url,
                display_name: review.reviewer.display_name
            },
            rating: review.rating,
            created: review.created_time, // Assuming Facebook review date format is compatible
            comment: review.review_text // Assuming Facebook review comment field is compatible
        };
      });

      var mergedReviews = [...mergedReviews, ...formattedFacebookReviews];
    }
  
    if(this.CATReviews && this.CATReviews.length > 0){
      var mergedReviews = [...mergedReviews, ...this.CATReviews];
    }
  
  
    // Step 3: Sort the merged array based on the created date in descending order
    const sortedReviews = mergedReviews.sort((a, b) => {
      // Parse the created dates as timestamps for comparison
      const timestampA = this.parseDate(a.created);
      const timestampB = this.parseDate(b.created);
  
      // Sort in descending order
      return timestampB - timestampA;
  });
  
 

    this.reviews = mergedReviews;

  }  


  parseDate(dateString: string): number {
    if (dateString.includes("WEEKS AGO")) {
      // Parse relative date
      const weeksAgo = parseInt(dateString);
      const currentDate = new Date();
      const millisecondsInAWeek = 7 * 24 * 60 * 60 * 1000;
      const targetDate = new Date(currentDate.getTime() - weeksAgo * millisecondsInAWeek);
      return targetDate.getTime();
  }

 
    // Check if the date is in "2 MONTHS AGO" format
    if (dateString.includes("MONTHS AGO")) {
        // Parse relative date
        const monthsAgo = parseInt(dateString);
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - monthsAgo);
        return currentDate.getTime();
    }
 


    // For other formats like "JULY 2023", you can parse it directly
    return Date.parse(dateString);
}



}
