import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as L from 'leaflet';

@Component({
  selector: 'app-lxytrack',
  templateUrl: './lxytrack.component.html',
  styleUrls: ['./lxytrack.component.scss']
})
export class LXYTrack implements OnInit, AfterViewInit {

  profileData: any;
  links: any[];
  bgcolor: any;
  map: L.Map;
  location: any;
  mapInitialized: boolean = false; // Flag to track map initialization

  lastLat:any;
  lastLong:any;

  constructor(
    private route: ActivatedRoute,
    private firestore: AngularFirestore
  ) { }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngAfterViewInit() {
    document.getElementsByClassName('container-fluid')[0].scrollIntoView();
    this.initializeMap(0, 0); // Initialize map with default coordinates
  }

  ngOnInit(): void {
    
    this.profileData={'logo':""};
    this.breadCrumbItems = [{ label: 'Locksley' }, { label: 'Tracking', active: true }];
    const onMyWayId = this.route.snapshot.paramMap.get('id');
    //console.log('Retrieved onMyWayId:', onMyWayId);
  
    if (onMyWayId) {
      this.getOnMyWayData(onMyWayId);
    } else {
      //console.error('No OnMyWay ID found in URL parameters');
    }
  }
  
  private async getOnMyWayData(onMyWayId: string) {
    if (!onMyWayId) {
      //console.error('No OnMyWay ID found');
      return;
    }
  
    const firestore = firebase.firestore();
    const docRef = firestore.collection('OnMyWay').doc(onMyWayId);
    docRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        const data = docSnapshot.data();
        const profileId = data?.uid;
        if (this.mapInitialized) {
           
          this.updateMapLocation(data.latitude,data.longitude);
        }

        //console.log('Retrieved profileId from OnMyWay:', profileId);
  
        if (profileId) {
          this.subscribeToLocationChanges(onMyWayId);
          this.getProfileData(profileId);
        } else {
          //console.error('No Profile ID found in OnMyWay data');
        }
      } else {
        //console.error('No document found with the given OnMyWay ID');
      }
    });
  }
  
  private async getProfileData(profileId: string) {
    if (!profileId) {
      //console.error('No Profile ID found');
      return;
    }
  
    this.firestore.collection('profiles').doc(profileId).valueChanges().subscribe((profile: any) => {
      //console.log('Retrieved profile data:', profile);
      this.profileData = profile;
      this.links = Object.keys(profile.Links).map(key => ({ name: key, url: profile.Links[key] }));
      this.bgcolor = profile.bgcolor;
      
      if (this.mapInitialized) {
        //console.log('update Map');
        this.updateMapLocation(this.lastLat,this.lastLong);
      }
    });

  }

  private async subscribeToLocationChanges(onMyWayId: string) {
    const firestore = firebase.firestore();
    const docRef = firestore.collection('OnMyWay').doc(onMyWayId);
    docRef.onSnapshot((docSnapshot) => {
      if (docSnapshot.exists) {
        const data = docSnapshot.data();
        this.location = { latitude: data?.latitude, longitude: data?.longitude };
        //console.log('Retrieved location data:', this.location);
  
        if (this.mapInitialized) {
          //console.log('update Map');
          this.updateMapLocation(this.location.latitude, this.location.longitude);
        }
      } else {
        //console.error('No document found with the given OnMyWay ID');
      }
    });
  }

  private updateMapLocation(latitude: number, longitude: number) {
    this.lastLat=latitude;
    this.lastLong=longitude;

    this.map.setView([latitude, longitude], this.map.getZoom());

    // Remove the existing marker
    this.map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        this.map.removeLayer(layer);
      }
    });

    // Add a new marker with updated location
    L.marker([latitude, longitude]).addTo(this.map)
      .bindPopup(this.profileData.displayName) // Use the profile display name in the popup
      .openPopup();
  }

  private initializeMap(latitude: number, longitude: number) {
    this.map = L.map('map').setView([latitude, longitude], 16);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    this.mapInitialized = true; // Set the flag to indicate map initialization
  }
}
