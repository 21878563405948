import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Home',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 2,
        label: 'About Us',
        icon: 'ri-dashboard-line',
        link: '/about'
    },
    {
        id: 3,
        label: 'App',
        icon: 'ri-dashboard-line',
        link: '/app'
    },
    {
        id: 4,
        label: 'Products',
        icon: 'ri-dashboard-line',
        link: '/products'
    }, 
    {
        id: 5,
        label: 'Account',
        icon: 'ri-dashboard-line',
        link: '/account/dashboard'
    },   
    {
        id: 6,
        label: 'Contact Us',
        icon: 'ri-dashboard-line',
        link: '/contact'
    }

];

