<div class="container-fluid">
  <app-pagetitle title="Contact Us" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>


  <div class="row">
    <div class="col-4">
      <div class="card">
        <h4 class="card-header mt-0">Contact Us</h4>
        <div class="card-body">
          <h4 class="card-title mt-0">We love to hear from you</h4>
        
            <dl class="card-text">
              <dt>Email</dt><dd><a href="mailto:hello@locksley.app" class="btn btn-primary"><i class="ri-mail-fill align-middle me-2"></i> hello@locksley.app</a></dd>
              <dt>WhatsApp</dt><dd><a style="background-color:#25d366;border-color:#25d366;" href="https://whatsapp.me/+447786153779" class="btn btn-primary"><i class="ri-whatsapp-fill align-middle me-2"></i> Chat Now</a></dd>

            </dl>

 
          
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <h4 class="card-header mt-0">Follow Us</h4>
        <div class="card-body">
          <h4 class="card-title mt-0">We love being social</h4>
        
            <dl class="card-text">
 <dt>Facebook</dt><dd><a style="background-color:#1877f2;border-color:#1877f2;" href="https://www.facebook.com/locksleyapp" class="btn btn-primary"><i class="ri-whatsapp-fill align-middle me-2"></i> locksleyapp</a></dd>
              <dt>Instagram</dt><dd><a style="background-color:#c32aa3;border-color:#c32aa3;" href="https://www.instagram.com/locksleyapp/" class="btn btn-primary"><i class="ri-instagram-fill align-middle me-2"></i> locksleyapp</a></dd>
              <dt>Twitter</dt><dd><a style="background-color:#1da1f2;border-color:#1da1f2;" href="https://twitter.com/locksleyapp" class="btn btn-primary"><i class="ri-twitter-fill align-middle me-2"></i> locksleyapp</a></dd>
              <dt>LinkedIn</dt><dd><a style="background-color:#0a66c2;border-color:#0a66c2;" href="https://www.linkedin.com/company/locksley-app" class="btn btn-primary"><i class="ri-linkedin-box-fill align-middle me-2"></i> locksley-app</a></dd>

            </dl>

 
          
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <h4 class="card-header mt-0">Watch Us</h4>
        <div class="card-body">
          <h4 class="card-title mt-0">More videos coming soon!</h4>
        
            <dl class="card-text">
              <dt>Youtube</dt><dd><a style="background-color:#ff0000;border-color:#ff0000;" href="https://www.youtube.com/@locksleyapp" class="btn btn-primary"><i class="ri-youtube-fill align-middle me-2"></i> @locksleyapp</a></dd>

            </dl>

 
          
        </div>
      </div>
    </div>
  </div>
</div>