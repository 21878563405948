import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CATReviewsService {
 
  private accessToken = '1607899336416096|9vpgZ2aN5SouLJs26Y4C_IIrUsw';
  private apiUrl = '';

  constructor(private http: HttpClient) { }

  getReviews(cid): Observable<any> {
    this.apiUrl = `https://adm.locksley.app/review-cat.php?cid=${cid}`;
    return this.http.get<any>(this.apiUrl);
  }
}