import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GooglePlacesService {
  private apiKey: string = 'AIzaSyAqt5904ALhhQ38V3hOOBdmpCVDpsOGcyA';

  constructor(private http: HttpClient) { }

  getReviews(placeId: string): Observable<any> {

   const url = `https://adm.locksley.app/review-google.php?pid=${placeId}`;
    return this.http.get(url);
  }
}

