import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FacebookReviewsService {
 
  private accessToken = '1607899336416096|9vpgZ2aN5SouLJs26Y4C_IIrUsw';
  private apiUrl = '';

  constructor(private http: HttpClient) { }

  getReviews(pageId): Observable<any> {
    this.apiUrl = `https://graph.facebook.com/v13.0/${pageId}/ratings?access_token=${this.accessToken}`;
    return this.http.get<any>(this.apiUrl);
  }
}