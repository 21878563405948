import firebase from 'firebase/compat/app';
// Add the Firebase products that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { jwtDecode } from 'jwt-decode';
import { Purchases, LOG_LEVEL } from '@revenuecat/purchases-capacitor';
import { Capacitor } from '@capacitor/core';

import { Router} from '@angular/router';

class FirebaseAuthBackend {
   

    constructor(firebaseConfig, Router) {
        
        if (firebaseConfig) {
            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
            firebase.auth().onAuthStateChanged(async (user) => {
                
                if (Capacitor.getPlatform() === 'ios') {
                    await Purchases.configure({ apiKey: 'appl_oBFqwVmDCKyOqMtBMAXRcmmCgiw' });
                } else if (Capacitor.getPlatform() === 'android') {
                    await Purchases.configure({ apiKey: 'goog_ufEIdsBnyqyGvSIfGSMrDAqlbqh' });
                }     
            
            
            
                if (user) {
                     
                    localStorage.setItem('authUser', JSON.stringify(user));

                    if(Capacitor.getPlatform() !=='web'){

                        await Purchases.logIn({ appUserID: user.uid});
                        var customerInfo =  await Purchases.getCustomerInfo();
                        var customerInfo2 = <any> customerInfo.customerInfo; //Recast to type any
                        
                        try{
                            if(typeof customerInfo2.entitlements.active['Combined']  !== "undefined") { //customerInfo2.activeSubscriptions[0] ='Combined'
                                // Grant user "pro" access
                                localStorage.setItem('stripeRole', 'Combined');
                                localStorage.setItem('subManageUrl', customerInfo2.managementURL);
                            } else {
                                //Check for Legacy Stripe packages on mobile
                                localStorage.setItem('stripeRole', (<any>jwtDecode(JSON.parse(localStorage.getItem('authUser')).stsTokenManager.accessToken)).stripeRole);
                                localStorage.setItem('subManageUrl', 'https://billing.stripe.com/p/login/14k8zO6gW5TTfV6eUU');
                                
                            }

 
                        }catch(e){
                            
                            localStorage.setItem('stripeRole', (<any>jwtDecode(JSON.parse(localStorage.getItem('authUser')).stsTokenManager.accessToken)).stripeRole);
                            localStorage.setItem('subManageUrl', 'https://billing.stripe.com/p/login/14k8zO6gW5TTfV6eUU');

                        }



                    } else {
                        //We're in web-mode, but lets check revenue Cat as well

                          const headers: Headers = new Headers()
                          // Add a few headers
                          headers.set('Content-Type', 'application/json')
                          headers.set('Accept', 'application/json')
                          // Add a custom header, which we can use to check
                          headers.set('Authorization', 'Bearer strp_NzoQTljidLyaiDwKmDDJiOJhtbJ')
                          headers.set('Access-Control-Allow-Origin', '*')
                        
                          // Create the request object, which will be a RequestInfo type. 
                          // Here, we will pass in the URL as well as the options object as parameters.
                          const request: RequestInfo = new Request('https://api.revenuecat.com/v1/subscribers/' + user.uid, {
                            method: 'GET',
                            headers: headers
                          });
                        
                          const res = await fetch(request);
                          var data = await res.json();
      
                        const customerInfo2: any = data.subscriber; //Recast to type any
                  
                       
                  
                        if(typeof customerInfo2.entitlements['Combined']  !== "undefined") {
                           
                            // Grant user "pro" access
                            localStorage.setItem('stripeRole', 'Combined');
                            if( customerInfo2.management_url !== null){
                                localStorage.setItem('subManageUrl', customerInfo2.management_url);
                            } else{
                                localStorage.setItem('subManageUrl', 'https://billing.stripe.com/p/login/14k8zO6gW5TTfV6eUU');
                            }
                         
                        } else {
                            //Check for Legacy Stripe packages on mobile
                            localStorage.setItem('stripeRole', (<any>jwtDecode(JSON.parse(localStorage.getItem('authUser')).stsTokenManager.accessToken)).stripeRole);
                            localStorage.setItem('subManageUrl', 'https://billing.stripe.com/p/login/14k8zO6gW5TTfV6eUU');
                        }
                   }
                    //TODO: Load up the RevCat Entitlements (RevCat)
                   // Router.navigate(['/account/dashboard']); 
 


                } else {
                    if(Capacitor.getPlatform() !=='web'){
                        Purchases.logOut();
                    }                    
                    localStorage.removeItem('authUser');
                    localStorage.removeItem('stripeRole');
                    localStorage.removeItem('subManageUrl');
                }
            });
        }
    }





    /**
     * Registers the user with given details
     */
    registerUser = (email, password) => {
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(email, password).then((user: any) => {
                var user: any = firebase.auth().currentUser;
                resolve(user);





            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * Login user with given details
     */
    loginUser = (email, password) => {
        return new Promise((resolve, reject) => {

            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => {
                firebase.auth().signInWithEmailAndPassword(email, password).then((user: any) => {

                    // eslint-disable-next-line no-redeclare
                    var user: any = firebase.auth().currentUser;
                    resolve(user);
                }, (error) => {
                    reject(this._handleError(error));
                });
            })
            .catch((error) => {
                reject(this._handleError(error));
            });









        });
    }

    /**
     * forget Password user with given details
     */
    forgetPassword = (email) => {
        return new Promise((resolve, reject) => {
            // tslint:disable-next-line: max-line-length
            firebase.auth().sendPasswordResetEmail(email, { url:'https://locksley.app' }).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            firebase.auth().signOut().then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            });
        });
    }

    setLoggeedInUser = (user) => {
        localStorage.setItem('authUser', JSON.stringify(user));
       
    }

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!localStorage.getItem('authUser')) {
            return null;
        }
        return JSON.parse(localStorage.getItem('authUser'));
    }

    /**
     * Handle the error
     * @param error error
     */
    _handleError(error) {
        // tslint:disable-next-line: prefer-const
        var errorMessage = error.message;
        return errorMessage;
    }
}

// tslint:disable-next-line: variable-name
let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param config configuration
 */
const initFirebaseBackend = (config, Router) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend(config, Router);
    }
    return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
